import { MintAuthenticationConfiguration } from './authConfiguration'
import { getConfiguration } from '../configuration/azure'

const initializeConfiguration = async () => {
  return getConfiguration().then(config => {
    let clientId = config.data.clientId
    return {
      auth: {
        clientId: clientId || '',
        authority: `https://login.microsoftonline.com/${config.data.tenantId}`,
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
      },
      scopes: [
        `${clientId}/NGS.User.API`
      ],
      tenantId: config.data.tenantId
    }
  })
}

let configuration: MintAuthenticationConfiguration

export const microsoftAuthConfiguration = async (): Promise<MintAuthenticationConfiguration> => {
  if (configuration) {
    return configuration
  } else {
    return initializeConfiguration().then(mintConfiguration => {
      configuration = mintConfiguration
      return configuration
    })
  }
}