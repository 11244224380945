import i18next, { i18n as i18nInstance } from 'i18next'
import { initReactI18next } from 'react-i18next'
import order from './translations/en/order.json'
import project from './translations/en/project.json'
import common from './translations/en/common.json'

const resources = {
  en: {
    'order': order,
    'project': project,
    'common': common
  }
}

const createI18n = (): i18nInstance => {
  const i18n = i18next.createInstance().use(initReactI18next)

  i18n.init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  })
  return i18n
}

export const i18n = createI18n()
