import React, { createContext, FC, useContext, useEffect, useMemo, useState } from 'react'
import { notifyError } from '../components/notification/Notification'
import { Buffer } from 'buffer'
import { accessToken } from '../lib/microsoftToken'
import { RoleRights, Roles, RoleState } from './roles'

interface RoleProviderProps {
  children: React.ReactNode
}

interface RoleContextType {
  roles: RoleState | null
  getSitesWithEditRightsOfType: (businessUnit: string) => string[]
}

const RoleContext = createContext<RoleContextType | undefined>(undefined)

const getRolesFromAccessToken = (token: string): string[] => {
  try {
    const idTokenClaims = JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf8'))
    return idTokenClaims.roles || []
  } catch (error) {
    return []
  }
}

const rolesInToken = async (): Promise<string[]> => {
  const token = await accessToken()
  return token ? getRolesFromAccessToken(token) : []
}

const getSitesWithEditRightsOfType = (roles: RoleState, businessUnit: string, rightType: string = RoleRights.EDIT): string[] => {
  const regex = new RegExp(`^${businessUnit}\\.([^.]+)\\.${rightType}$`, 'i')

  return Object.keys(roles)
    .filter(roleKey =>
      roleKey.startsWith(`${businessUnit}.`) && roleKey.toLowerCase().endsWith(`.${rightType.toLowerCase()}`) && roles[roleKey]
    )
    .map(roleKey => {
      const match = regex.exec(roleKey)
      return match ? match[1] : null
    })
    .filter(site => site !== null) as string[]
}

export const RoleProvider: FC<RoleProviderProps> = ({ children }) => {
  const [roles, setRoles] = useState<RoleState | null>(null)

  useEffect(() => {
    const checkRolePresence = async () => {
      try {
        const tokenRoles = await rolesInToken()
        const rolesFetched: RoleState = {}

        Object.values(Roles).forEach((role) => {
          rolesFetched[role] = tokenRoles.includes(role)
        })

        setRoles(rolesFetched)
      } catch (error: any) {
        notifyError(error)
      }
    }
    checkRolePresence()
  }, [])

  const contextValue = useMemo(() => ({
    roles,
    getSitesWithEditRightsOfType: (businessUnit: string) => roles ? getSitesWithEditRightsOfType(roles, businessUnit) : []
  }), [roles])

  if (roles === null) {
    return null
  }

  return (
    <RoleContext.Provider value={contextValue}>
      {children}
    </RoleContext.Provider>
  )
}

export const useRoleContext = (): RoleContextType => {
  const context = useContext(RoleContext)
  if (!context) {
    throw new Error('useRoleContext must be used within RoleProvider')
  }
  return context
}

export const useRole = (role: Roles): boolean | undefined => {
  const context = useContext(RoleContext)
  if (!context) {
    throw new Error('useRole must be used within RoleProvider')
  }

  const { roles } = context
  return !!roles?.[role]
}

export const useRoles = (): RoleState | null => {
  const context = useContext(RoleContext)
  return context?.roles ?? null
}
