import { PublicClientApplication } from '@azure/msal-browser'
import { microsoftAuthConfiguration } from './mintAuthConfiguration'


let mintAuthInstance: PublicClientApplication | undefined = undefined

export const msalInstance = async () => {
  if (mintAuthInstance) {
    return mintAuthInstance
  } else {
    return microsoftAuthConfiguration().then(configuration => {
      mintAuthInstance = new PublicClientApplication(configuration)
      mintAuthInstance.initialize()
      return mintAuthInstance
    })
  }
}