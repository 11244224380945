import { HttpStatus } from '../../lib/http/httpApi'
import { Button, notification } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons'

const createRandomStringKey = () => {
  return Math.random().toString(36).slice(2, 12)
}
const notifyError = async (error: any) => {
  let title = error.status == HttpStatus.BAD_REQUEST ?
    'Error' :
    'System Error'
  notification.error({
    message: title,
    description: error.message
  })
}

const notifyLoading = (message: string) => {
  let key = createRandomStringKey()
  notification.open({
    message: message,
    icon: <LoadingOutlined/>,
    key: key
  })
  return key
}

const notifySuccess = (message: string) => {
  notification.open({
    message: message,
    icon: <CheckCircleOutlined style={{ color: 'green' }}/>,
    duration: 2
  })
}
const notifyFailure = (message: string, duration = 2) => {
  notification.open({
    message: message,
    icon: <CloseCircleOutlined style={{ color: 'red' }}/>,
    duration: duration
  })
}

const closeNotification = (key: string) => {
  notification.destroy(key)
}

const destroyPreviousErrorAndTryAgain = (previousNotificationKey: string, funcTryAgain: () => void) => () => {
  notification.destroy(previousNotificationKey)
  funcTryAgain()
}

const getButtonPopupButton = (funcButton: () => void, notificationKey: string, titleButton: string) => {
  return <Button type="primary" onClick={destroyPreviousErrorAndTryAgain(notificationKey, funcButton)}>
    {titleButton}
  </Button>
}

const notifyErrorWithButton = (buttonFunction: () => void, popupMessage: string, buttonTitle: string, popupDescription = '') => {
  const notificationKey = `open${Date.now()}`

  notification.open({
    message: popupMessage,
    description: popupDescription,
    key: notificationKey,
    btn: getButtonPopupButton(buttonFunction, notificationKey, buttonTitle),
    closeIcon: (
      <div></div>
    ),
    duration: 0,
    type: 'error'
  })
}

const notifyWarningWithButton = (buttonFunction: () => void, popupMessage: string, buttonTitle: string, popupDescription = '') => {
  const notificationKey = `open${Date.now()}`

  notification.open({
    message: popupMessage,
    description: popupDescription,
    key: notificationKey,
    btn: getButtonPopupButton(buttonFunction, notificationKey, buttonTitle),
    duration: 10,
    type: 'warning'
  })
}

export {
  notifyError,
  notifyLoading,
  notifySuccess,
  notifyFailure,
  closeNotification,
  notifyErrorWithButton,
  notifyWarningWithButton
}