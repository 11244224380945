import { get } from '../lib/axios'
import { LabConfigurationResponse } from './configuration'
import { useQuery } from '@tanstack/react-query'

const fetchLabConfiguration = async () => {
  return await get<LabConfigurationResponse>('/api/configuration/lab')
}

export const getLabConfiguration = () => {
  return useQuery({
    staleTime: Infinity,
    queryKey: ['labConfiguration'],
    queryFn: () => fetchLabConfiguration()
  })
}
