import './App.scss'
import { AppProvider } from './provider/AppProvider'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useEffect } from 'react'
import { addLoginSuccessCallback } from './auth/loginSuccessCallback'
import { ConfigProvider } from 'antd'
import { isTestMode } from './testMode'
import { RoleProvider } from './provider/RoleProvider'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
})

export const App = () => {
  useEffect(() => {
    if (!isTestMode()) {
      addLoginSuccessCallback()
    }
  }, [])

  return (
    <div className="App">
      <RoleProvider>
        <QueryClientProvider client={queryClient}>
          <ConfigProvider theme={{
            token: {
              colorPrimary: '#00b96b',
              colorBgLayout: ''
            },
          }}>
            <AppProvider/>
          </ConfigProvider>
        </QueryClientProvider>
      </RoleProvider>
    </div>
  )
}

