import { Layout, theme } from 'antd'
import { useNavigate } from 'react-router-dom'
import './Header.scss'
import logo from '../../assets/eurofins.png'

const { Header: AntdHeader } = Layout
const { useToken } = theme

export const Header = () => {
  const navigate = useNavigate()
  const { token : globalTheme } = useToken()
  return (
    <AntdHeader style={{ backgroundColor: globalTheme.colorPrimary }}>
      <div className="logo" onClick={() => navigate('/')} aria-hidden="true">
        <img src={logo} className="logo-image grayscale" alt="Eurofins Logo"/>
        <div>Mint</div>
      </div>
    </AntdHeader>
  )
}
