import { Suspense } from 'react'
import { Spin } from 'antd'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'
import { RouterProvider } from 'react-router-dom'
import { router } from '../routes'
import { getLabConfiguration } from '../configuration/labConfiguration'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

export const AppProvider = () => {
  const { data: labConfiguration } = getLabConfiguration()
  dayjs.extend(utc)
  dayjs.extend(timezone)
  if (labConfiguration?.zoneId) {
    dayjs.tz.setDefault(labConfiguration.zoneId)
  }

  return (
    <Suspense fallback={<Spin/>}>
      <ErrorBoundary>
        <RouterProvider router={router}/>
      </ErrorBoundary>
    </Suspense>
  )
}
