import React, { createContext, PropsWithChildren, useContext } from 'react'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const AppInsightsContext = createContext<ApplicationInsights | null>(null)

type AppInsightsProviderProps = PropsWithChildren<{ appInsights: ApplicationInsights }>

export const AppInsightsProvider: React.FC<AppInsightsProviderProps> = ({ appInsights, children }) => {
  return (
    <AppInsightsContext.Provider value={appInsights}>
      {children}
    </AppInsightsContext.Provider>
  )
}

export const useAppInsights = () => useContext(AppInsightsContext)
