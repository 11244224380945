// Generated using typescript-generator version 3.2.1263 on 2024-11-12 13:40:26.

export interface AddInvoiceOrderItemKitComponentRequest {
    kitComponentNumber?: string;
    kitNumber?: string;
    version?: number;
}

export interface Address {
    addressId?: string;
    city?: string;
    country?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    line1?: string;
    line2?: string;
    line3?: string;
    phone?: string;
    street?: string;
    title?: string;
    zip?: string;
}

export interface DataStorageInformation {
    dataRemovedFromExternalStorage?: boolean;
    extendedDataAvailabilityUntil?: Date;
    extendedDataStorageTaskShown?: boolean;
    extendedDataStorageUntil?: Date;
}

export interface DevOpsTicketDto {
    id?: string;
    type?: string;
    url?: string;
}

export interface DiscardSamplesRequest {
    samplesDiscardedDate: Date;
}

export interface DueDateChange {
    arrivalDate?: Date;
    changePuid?: string;
    changeTimestamp?: Date;
    dueDateChangeType?: DueDateChangeType;
    newDate?: Date;
    oldDate?: Date;
}

export interface EditInvoiceBillCommentRequest {
    billComment: string;
    version: number;
}

export interface EditInvoiceInternalCommentRequest {
    internalComment: string;
    version: number;
}

export interface EditKitComponentRequest {
    invoiceQuantity: number;
    kitComponentNumber: string;
    price: number;
    version: number;
}

export interface InformCustomerRequest {
    comment?: string;
    decisionOptions?: CustomerDecisionOptions[];
    reportNames?: QcReportName[];
    samplesAndLibrariesIds: { [index: string]: string[] };
    useAutomaticProcess?: boolean;
}

export interface InvoiceConfirmRequest {
    version: number;
}

export interface InvoiceDto {
    billComment?: string;
    currency?: string;
    id?: string;
    internalComment?: string;
    orderItems?: InvoiceOrderItem[];
    salesOrderBillComment?: string;
    timeConfirmed?: Date;
    timeUpdated?: Date;
    version?: number;
}

export interface InvoiceKitComponent {
    description?: string;
    invoiceQuantity: number;
    kitComponentNumber: string;
    longDescription?: string;
    orderQuantity: number;
    price: number;
    tax: number;
}

export interface InvoiceOrderItem {
    kitComponents?: InvoiceKitComponent[];
    kitNumber?: string;
    orderItemId?: string;
    salesItemType?: string;
}

export interface LibrariesByOrder {
    librariesCancelled?: boolean;
    libraryBarcodes?: string[];
    orderId?: string;
    orderNumber?: string;
}

export interface LibrariesInPoolDto {
    librariesByOrder: LibrariesByOrder[];
    poolBarcode: string;
    poolId: string;
    sentToProcessing?: boolean;
}

export interface LibrarySetupInfo {
    batchType?: NgsBatchType;
    libraryType?: string;
    onHoldConstraintId?: string;
    onHoldConstraintType?: NgsLibraryConstraintType;
    onHoldQcStatuses?: NgsLibraryOnHoldQcStatus[];
    plannedReads?: number;
    sequencerProperties?: SequencerProperties;
    target?: string;
}

export interface LibraryStatusOverrideRequest {
    comment?: string;
    libraryUuids: string[];
    newStatus: NgsLibraryProcessingStatusType;
}

export interface LibraryWithPlannedReadsRequest {
    libraryData: LibraryWithPlannedReadsRequestData[];
    productionOrderId: string;
}

export interface LibraryWithPlannedReadsRequestData {
    libraryId?: string;
    missedReads?: number;
    plannedReads?: number;
}

export interface ListResult<T> {
    currentPage?: number;
    data?: T[];
    pageSize?: number;
    totalElements?: number;
}

export interface NgsDetailsDto {
    originalCustomerSamples?: OriginalCustomerSamples;
    questionnaire?: Questionnaire;
}

export interface NgsFileDeliveryDto {
    bioItTickets?: DevOpsTicketDto[];
    creationTime?: Date;
    deliveryTime?: Date;
    filename?: string;
    lane?: number;
    runId?: string;
    runQcStatus?: RunQcStatus;
    sequencerFile?: boolean;
    status?: NgsFileDeliveryStatus;
    successfulSequencerFile?: boolean;
    target?: string;
}

export interface NgsLibraryDto {
    batchType?: NgsBatchType;
    constraintId?: string;
    constraintType?: NgsLibraryConstraintType;
    delivered?: boolean;
    finalLibQcStatus?: LibQcStatus;
    gxp?: boolean;
    id?: string;
    lastUpdateDate?: Date;
    libQc1Status?: LibQcStatus;
    libQc2Status?: LibQcStatus;
    libQcOverwrite?: boolean;
    libraryBarcode?: string;
    libraryCreationDate?: Date;
    libraryStatus?: NgsLibraryProcessingStatusType;
    libraryTypeName?: string;
    onHoldQcStatuses?: NgsLibraryOnHoldQcStatus[];
    plannedReads?: number;
    poolingData?: PoolingData;
    preHybLibQcStatus?: LibQcStatus;
    readyForLibPrep?: boolean;
    redoLibraryId?: string;
    rerun?: boolean;
    runQcResults?: NgsRunQcResultDto[];
    runs?: NgsLibraryRunDto[];
    sequencerProperties?: SequencerProperties;
    tagData?: TagData;
    targetData?: TargetData;
}

export interface NgsLibraryRunDto {
    laneId?: number;
    plannedStartDate?: Date;
    readMode?: string;
    runId?: string;
    sequencerType?: string;
    sequencingStartDate?: Date;
}

export interface NgsOrderFileDto {
    creationTime?: Date;
    deliveryTime?: Date;
    filename?: string;
    id?: string;
    status?: NgsFileDeliveryStatus;
    type?: NgsOrderFileType;
}

export interface NgsOrderProcessingDetailsDto {
    sampleSummaries?: SampleSummaries;
    samples?: NgsSampleDto[];
}

export interface NgsRunQcResultDto {
    creationTime?: Date;
    lane?: number;
    laneReleased?: boolean;
    mappedReads?: number;
    runId?: string;
    status?: RunQcStatus;
}

export interface NgsSampleDto {
    batchType?: NgsBatchType;
    bioIt?: boolean;
    bioItComment?: string;
    containerBarcode?: string;
    containerPosition?: string;
    deliveries?: NgsFileDeliveryDto[];
    dueDateForBioIt?: Date;
    dueDateForBioItHistory?: DueDateChange[];
    dueDateForRawData?: Date;
    dueDateForRawDataHistory?: DueDateChange[];
    entryQcStatus?: EntryQcStatus;
    eqcOverwrite?: boolean;
    extractionRequired?: boolean;
    guaranteedReads?: number;
    gxp?: boolean;
    hasTargets?: boolean;
    id?: string;
    lastUpdateDate?: Date;
    libraries?: NgsLibraryDto[];
    orderedReads?: number;
    overrideArrivalDate?: Date;
    processingStatus?: NgsProcessingStatusType;
    readyForLibPrep?: boolean;
    sampleBarcode?: string;
    sampleComment?: string;
    sampleIssueOverwrite?: boolean;
    sampleNameCustomer?: string;
    sampleNameProduction?: string;
    sampleReceptionInformation?: ReceptionInformation;
    sampleSource?: string;
    sequencedFilesAvailableByTarget?: { [index: string]: boolean };
}

export interface OrderDto {
    productionOrder: ProductionOrderDto;
    salesOrder: SalesOrderDto;
}

export interface OrderListResult extends ListResult<OrderDto> {
    data?: OrderDto[];
}

export interface OrderSetupGeneralInfo {
    additionalCustomerContacts?: string;
    bioIT?: number;
    comment?: string;
    express?: boolean;
    extendedDataAvailabilityUntil?: Date;
    extendedDataStorageUntil?: Date;
    extendedSampleStorageUntil?: Date;
    gxp?: boolean;
    handledByProjectManagers?: boolean;
    orderType?: string;
    personInCharge?: string;
    rawData?: number;
    responsibleSalesPerson?: string;
    samplesDiscardedDate?: Date;
    samplesShippedBackDate?: Date;
    shipSamplesBack?: boolean;
    specialHandling?: boolean;
    submitted?: boolean;
    tatTotal?: number;
}

export interface OrderStatusOverrideDto {
    newStatus?: ProductionOrderStatus;
    oldStatus?: ProductionOrderStatus;
    productionOrderId?: string;
}

export interface OriginalCustomerSamples {
    originalSamples?: OriginalSample[];
}

export interface OriginalSample {
    bufferOrPreservative?: string;
    column?: string;
    containerName?: string;
    containerType?: string;
    dnaConcentration?: string;
    longBarcode?: string;
    row?: string;
    sampleAmount?: string;
    sampleBarcode?: string;
    sampleCollectionDate?: Date;
    sampleName?: string;
    sampleShortCode?: string;
    sampleSource?: string;
    templateType?: string;
}

export interface PaymentInformationDto {
    poNumber?: string;
}

export interface PoolViewDto {
    gxp?: boolean;
    orderId?: string;
    orderNumber?: string;
    poolBarcode?: string;
    poolId?: string;
    samples?: NgsSampleDto[];
}

export interface PoolWithPlannedReadsRequest {
    libraryData: LibraryWithPlannedReadsRequestData[];
    poolId: string;
}

export interface PoolingData {
    poolBarcode?: string;
    poolId?: string;
    poolProjectName?: string;
    poolSampleName?: string;
    poolType?: string;
}

export interface ProcessStatusStep {
    eventTime?: Date;
    puid?: string;
    step?: string;
}

export interface ProductionOrderDto {
    additionalCustomerContacts?: string;
    businessUnit?: BusinessUnit;
    creationDate?: Date;
    dataStorageInformation?: DataStorageInformation;
    expectedDeliveryDate?: Date;
    express?: boolean;
    gxp?: boolean;
    handledByProjectManagers?: boolean;
    id: string;
    lastUpdateDate?: Date;
    numberOfSamples?: number;
    orderComment?: string;
    orderItemsComments?: string[];
    orderLockStatus?: NglimsOrderLockStatus;
    orderNumber?: string;
    orderStatus: string;
    personInCharge?: string;
    productName?: string;
    productionOrderExternalId?: string;
    productionSite?: ProductionSite;
    responsibleSalesPerson?: string;
    salesOrderId?: string;
    samplesStorageInformation?: SamplesStorageInformation;
    specialHandling?: boolean;
}

export interface Questionnaire {
    userAnswers?: UserAnswer[];
}

export interface ReceptionInformation {
    arrivalDate?: Date;
    arrivalTemperature?: string;
    isRgcAvailable?: boolean;
    nextStep?: string;
    orderIssues?: string[];
    receptionComment?: string;
    sampleIssueComment?: string;
    sampleStatus?: SampleReceptionStatus;
    storageLocation?: string;
    storageTemperature?: string;
    userId?: string;
}

export interface SalesOrderDto {
    billing?: Address;
    comment?: string[];
    confirmedDate?: Date;
    contact?: Address;
    distributor?: string;
    id?: string;
    orderStatus?: string;
    paymentOptions?: PaymentInformationDto[];
    priceAgreement?: string;
    quote?: boolean;
    salesOrderExternalId?: string;
}

export interface SampleArrivalDateRequest {
    arrivalDate: Date;
    sampleIds: string[];
}

export interface SampleCommentUpdateRequest {
    sampleComment?: string;
    sampleIds: string[];
    updateType: UpdateType;
}

export interface SampleDueDateUpdateRequest {
    dueDateForBioIt?: Date;
    dueDateForRawData?: Date;
    sampleIds: string[];
}

export interface SampleSetupInfo {
    batchType?: NgsBatchType;
    bioIt?: boolean;
    bioItComment?: string;
    extractionRequired?: boolean;
    extractionType?: string;
    guaranteedReads?: number;
    orderedReads?: number;
    sampleComment?: string;
    sampleSource?: string;
    storageTemperature?: string;
    templateType?: string;
}

export interface SampleStatusOverrideRequest {
    comment?: string;
    newStatus: NgsProcessingStatusType;
    sampleIds: string[];
}

export interface SampleSummaries {
    anySampleHasBioIt?: boolean;
    anySampleHasDeliveries?: boolean;
    anySampleHasExtraction?: boolean;
    anySampleIsActive?: boolean;
    cancelledBeforeArrival?: number;
    cancelledInProcess?: number;
    rawDataDelivered?: number;
    receivable?: number;
    received?: number;
    samplesReadyToBeDiscarded?: boolean;
    setupSamples?: number;
    uniqueEntryQcStatuses?: string[];
    uniqueLibQc1Statuses?: string[];
    uniqueLibQc2Statuses?: string[];
    uniqueLibQcStatuses?: string[];
    uniqueLibraryStatuses?: NgsLibraryProcessingStatusType[];
    uniqueLibraryTypeNames?: LibraryType[];
    uniquePreHybLibQcStatuses?: string[];
    uniqueRunIds?: string[];
    uniqueSampleBioItDueDates?: Date[];
    uniqueSampleRawDataDueDates?: Date[];
    uniqueSampleReceptionDates?: Date[];
    uniqueSampleStatuses?: NgsProcessingStatusType[];
    uniqueTargetData?: string[];
}

export interface SamplesShippedBackRequest {
    samplesShippedBackDate: Date;
}

export interface SamplesStorageInformation {
    extendedSampleStorageTaskShown?: boolean;
    extendedSampleStorageUntil?: Date;
    sampleStorageUntil?: Date;
    samplesDiscardedDate?: Date;
    samplesDiscardedPuid?: string;
    samplesShippedBackDate?: Date;
    samplesShippedBackPuid?: string;
    shipSamplesBack?: boolean;
}

export interface SequencerProperties {
    readLength?: number;
    readMode?: string;
    sequencerType?: string;
}

export interface TagData {
    plateBarcode?: string;
    platePos?: string;
    tagManufacturerIndex?: string;
    tagPlateBarcode?: string;
    tagPlateId?: string;
    tagPlatePos?: string;
    tagSequence?: string;
}

export interface TargetData {
    guaranteedReads?: number;
    target?: string;
}

export interface UserAnswer {
    answerIds?: string[];
    answerString?: string;
    questionId?: string;
    questionText?: string;
}

export enum BusinessUnit {
    NGS = 'NGS',
    MICRO_ARRAY = 'MICRO_ARRAY',
}

export enum CustomHttpHeaders {
    GXP_COMMENT = 'X-Gxp-Comment',
}

export enum CustomerDecisionOptions {
    PROCEED_WITH_PROCESSING = 'PROCEED_WITH_PROCESSING',
    SEND_REPLACEMENT = 'SEND_REPLACEMENT',
    CANCEL = 'CANCEL',
}

export enum DueDateChangeType {
    RECEIVED = 'RECEIVED',
    RGC_AVAILABLE = 'RGC_AVAILABLE',
    PROCEEDED = 'PROCEEDED',
    ARRIVAL_DATE = 'ARRIVAL_DATE',
    DUE_DATE = 'DUE_DATE',
}

export enum EntryQcStatus {
    PASSED = 'PASSED',
    FAILED = 'FAILED',
    NO_RESULT_YET = 'NO_RESULT_YET',
}

export enum LibQcStatus {
    LIB_QC = 'LIB_QC',
    LIB_QC_1 = 'LIB_QC_1',
    LIB_QC_1_FAILED = 'LIB_QC_1_FAILED',
    LIB_QC_2 = 'LIB_QC_2',
    LIB_QC_2_FAILED = 'LIB_QC_2_FAILED',
    LIB_QC_POOL = 'LIB_QC_POOL',
    PREHYB_QC = 'PREHYB_QC',
    AMPLIFICATION = 'AMPLIFICATION',
    PRESEQ_POOL_START = 'PRESEQ_POOL_START',
    TUBE_TRANSFER = 'TUBE_TRANSFER',
    PREHYB_POOLING = 'PREHYB_POOLING',
    LIB_PREP_START = 'LIB_PREP_START',
    PREHYB_QC_FAILED = 'PREHYB_QC_FAILED',
    CDNA_TRANSFER = 'CDNA_TRANSFER',
    DISCUSS_RESULTS = 'DISCUSS_RESULTS',
    REDO_LIB_SAMETAG = 'REDO_LIB_SAMETAG',
    REDO_LIB_NEWTAG = 'REDO_LIB_NEWTAG',
    REDO_POOLING = 'REDO_POOLING',
    LIB_PREP_COMPLETE = 'LIB_PREP_COMPLETE',
    NO_RESULT_YET = 'NO_RESULT_YET',
}

export enum LibraryType {
    A_L_HS = 'A.L. HS',
    A_L_MS = 'A.L. MS',
    CELL_FREE_DNA = 'Cell-free DNA',
    HS_TS_DNA = 'HS TS DNA',
    HS_TS_FFPE = 'HS TS FFPE',
    HS_TS_RNA = 'HS TS RNA',
    LI_ILLU = 'LI Illu',
    MBP_ILLU = 'MBP Illu',
    RTL = 'RTL',
    SSTS_RNA = 'SsTS RNA',
    WGBS_ILLU = 'WGBS Illu',
    EXOM_EXPL_FFPE = 'Exom_Expl_FFPE',
    EXOM_EXPL_STD = 'Exom_Expl_Std',
    SURESELECT = 'SureSelect',
    SURESELECT_200NG = 'SureSelect_200ng',
    SURESELECT_FFPE = 'SureSelect_FFPE',
    SURESELECT_MANUELL = 'SureSelect_manuell',
    M_I_RNA = 'miRNA',
    LOW_RNA = 'LOW_RNA',
    RNA_EXOME = 'RNA_exome',
    RNA_FUSION_STD = 'RNA_Fusion_Std',
    RNA_FUSION_FFPE = 'RNA_Fusion_FFPE',
    RNA_POLY_A = 'RNA_polyA',
    RNA_H_M_R_DEPL = 'RNA_HMRDepl',
    RNA_BAC_DEPL = 'RNA_BacDepl',
    RNA_PLANT_DEPL = 'RNA_PlantDepl',
    RNA_GLOB = 'RNA_Glob',
    RNA_HMR_GLOB = 'RNA_HMR_Glob',
    RNA_FFPE_HMR = 'RNA_FFPE_HMR',
    RNA_FFPE_POLY_A = 'RNA_FFPE_polyA',
    RNA_TOTAL = 'RNA_total',
    RNA_POLY_A_GLOB = 'RNA_polyA_Glob',
    RNA_SPECIAL = 'RNA_Special',
    RNA_HMR_BAC_DEPL = 'RNA_HMR_BacDepl',
    RNA_VIRUS = 'RNA_Virus',
    R_N_D = 'RnD',
    AL_OTQ = 'AL_OTQ',
    MIDPLEX = 'Midplex',
    HIGH_THROUGHPUT_16S = '16S_HT',
    BD_RHAPSODY = 'BD_Rhapsody',
    SELEXIS = 'Selexis',
    AVT = 'AVT',
    PCR_FREE = 'PCRfree',
    SECOND_PCR = '2nd_PCR',
    ONT_GDNA = 'ONT gDNA',
    ONT_RAPID = 'ONT rapid',
    ONT_AMPLICON = 'ONT Amplicon',
    ONT_PCR = 'ONT PCR',
    OLINK_STD = 'Olink Std',
    OLINK_RTL = 'Olink RTL',
    MINI_AMP = 'Mini_Amp',
}

export enum NglimsOrderLockStatus {
    PROCESSED_IN_NGLIMS = 'PROCESSED_IN_NGLIMS',
    PROCESSED_EXTERNALLY = 'PROCESSED_EXTERNALLY',
}

export enum NgsBatchType {
    STANDARD_PROCESSING = 'STANDARD_PROCESSING',
    STRICT_ON_HOLD = 'STRICT_ON_HOLD',
    BATCH_PROCESSING = 'BATCH_PROCESSING',
}

export enum NgsFileDeliveryStatus {
    AVAILABLE = 'AVAILABLE',
    DELIVERABLE = 'DELIVERABLE',
    DELIVERED = 'DELIVERED',
    DELIVERY_IN_PROGRESS = 'DELIVERY_IN_PROGRESS',
    FILE_MERGED = 'FILE_MERGED',
}

export enum NgsLibraryConstraintType {
    INDEPENDENT = 'independent',
    BATCH_WISE = 'batch',
    EXCLUSIVE = 'exclusive',
}

export enum NgsLibraryOnHoldQcStatus {
    FINAL_LIB_QC = 'FINAL_LIB_QC',
}

export enum NgsLibraryProcessingStatusType {
    NOT_SET = 'not_set',
    ANNOUNCED = 'ANNOUNCED',
    IN_PROGRESS = 'in_progress',
    CANCELLED = 'cancelled',
    START_DILUTION = 'start_dilution',
    LIB_PREP_START = 'LIB_PREP_START',
    LIB_QC_1_DONE = 'lib_qc_1_done',
    LIB_QC_1_FAILED = 'lib_qc_1_failed',
    LIB_QC_1_REDO = 'lib_qc_1_redo',
    WAITING_FOR_CUSTOMER_LIB_QC_1 = 'waiting_for_customer_lib_qc_1',
    LIB_QC_2_DONE = 'lib_qc_2_done',
    LIB_QC_2_FAILED = 'lib_qc_2_failed',
    LIB_QC_2_REDO = 'lib_qc_2_redo',
    WAITING_FOR_CUSTOMER_LIB_QC_2 = 'waiting_for_customer_lib_qc_2',
    LIB_PREP_FINISHED = 'lib-prep-finished',
    LIB_QC_DONE = 'lib_qc_done',
    LIB_QC = 'lib_qc',
    ON_HOLD_LIB_QC = 'on_hold_lib_qc',
    WAITING_FOR_CUSTOMER_ON_HOLD_LIB_QC = 'waiting_for_customer_on_hold_lib_qc',
    LIB_QC_FAILED = 'lib_qc_failed',
    LIB_QC_REDO = 'lib_qc_redo',
    WAITING_FOR_CUSTOMER_LIB_QC = 'waiting_for_customer_lib_qc',
    WAITING_FOR_CUSTOMER_LIB_REDO_QC_1 = 'waiting_for_customer_lib_redo_qc_1',
    WAITING_FOR_CUSTOMER_LIB_REDO_QC_2 = 'waiting_for_customer_lib_redo_qc_2',
    WAITING_FOR_CUSTOMER_LIB_REDO_PREHYB_QC = 'waiting_for_customer_lib_redo_prehyb_qc',
    WAITING_FOR_CUSTOMER_LIB_REDO_FINAL_QC = 'waiting_for_customer_lib_redo_final_qc',
    PREHYB_LIB_QC = 'prehyb_lib_qc',
    PREHYB_LIB_QC_FAILED = 'prehyb_lib_qc_failed',
    PREHYB_LIB_QC_REDO = 'prehyb_lib_qc_redo',
    PREHYB_LIB_QC_DONE = 'prehyb_lib_qc_done',
    PRESEQ_POOL_START = 'PRESEQ_POOL_START',
    PREHYB_POOLING = 'PREHYB_POOLING',
    SIZE_SELECTION = 'SIZE_SELECTION',
    FS_ER_AL = 'FS_ER_AL',
    PREHYB_POOL_CREATED = 'PREHYB_POOL_CREATED',
    PRESEQ_POOL_CREATED = 'PRESEQ_POOL_CREATED',
    WAIT_FOR_POOL_SEQ = 'WAIT_FOR_POOL_SEQ',
    POOLING_START = 'POOLING_START',
    POOLING_FINISHED = 'POOLING_FINISHED',
    HYBRIDISATION = 'HYBRIDISATION',
    CAPTURE_AND_WASH = 'CAPTURE_AND_WASH',
    POST_HYB_AMPLIFICATION = 'POST_HYB_AMPLIFICATION',
    POST_HYB_PURIFICATION = 'POST_HYB_PURIFICATION',
    WAITING_FOR_CUSTOMER_PREHYB_LIB_QC = 'waiting_for_customer_prehyb_lib_qc',
    REDO_POOLING = 'redo_pooling',
    WAITING_FOR_SAMPLE_REPLACEMENT = 'waiting_for_sample_replacement',
    READS_QC = 'reads_qc',
    READQC_DONE = 'readqc_done',
    WAITING_FOR_CUSTOMER_READ_QC = 'waiting_for_customer_read_qc',
    RUN_PLANNED = 'RUN_PLANNED',
    RUN_SCHEDULED = 'RUN_SCHEDULED',
    DP_STARTED = 'DP_STARTED',
    DP_COMPLETED = 'DP_COMPLETED',
    PREPARE_DATA_FOR_ANALYSIS = 'PREPARE_DATA_FOR_ANALYSIS',
    SEQ_RUN_STARTED = 'SEQ_RUN_STARTED',
    SEQ_RUN_FINISHED = 'SEQ_RUN_FINISHED',
    READY_FOR_RUNQC = 'READY_FOR_RUNQC',
    MANUAL_RUNQC_STARTED = 'MANUAL_RUNQC_STARTED',
    MANUAL_RUNQC_FINISHED = 'MANUAL_RUNQC_FINISHED',
    PREPARING_FOR_DELIVERY = 'PREPARING_FOR_DELIVERY',
    DELIVERABLE = 'deliverable',
    DELIVERED = 'delivered',
    BIOIT_IN_PROCESS = 'BIOIT_IN_PROCESS',
    BIOIT_FINISHED = 'BIOIT_FINISHED',
    PURIFICATION = 'PURIFICATION',
    PURIFICATION_1 = 'PURIFICATION_1',
    PURIFICATION_2 = 'PURIFICATION_2',
    AMPLIFICATION = 'AMPLIFICATION',
    END_REPAIR = 'END_REPAIR',
    ENRICHMENT = 'ENRICHMENT',
    MERGING = 'MERGING',
    NORMALIZATION = 'NORMALIZATION',
    PROFILING_PCR_1 = 'PROFILING_PCR_1',
    CDNA_SYNTHESIS = 'CDNA_SYNTHESIS',
    CDNA_TRANSFER = 'CDNA_TRANSFER',
    PA_STARTED = 'PA_STARTED',
    PA_FINISHED = 'PA_FINISHED',
    FRAGMENTATION = 'FRAGMENTATION',
    ADAPTER_LIGATION = 'ADAPTER_LIGATION',
    TUBE_TRANSFER = 'TUBE_TRANSFER',
    UNSUPPORTED = 'UNSUPPORTED',
    LIB_QC_1 = 'LIB_QC_1',
    PREHYB_QC = 'PREHYB_QC',
    PREHYB_QC_FAILED = 'PREHYB_QC_FAILED',
    DISCUSS_RESULTS = 'DISCUSS_RESULTS',
    REDO_PLANNING = 'REDO_PLANNING',
}

export enum NgsOrderFileType {
    ANALYSIS = 'ANALYSIS',
    REPORT = 'REPORT',
}

export enum NgsProcessingStatusType {
    NOT_SET = 'NOT_SET',
    ANNOUNCED = 'ANNOUNCED',
    RECEIVED = 'RECEIVED',
    WRONG_MATERIAL_SENT = 'WRONG_MATERIAL_SENT',
    WAITING_FOR_CUSTOMER_WRONG_MATERIAL_SENT = 'WAITING_FOR_CUSTOMER_WRONG_MATERIAL_SENT',
    RGC_REQUIRED = 'RGC_REQUIRED',
    WAITING_FOR_CUSTOMER_RGC_REQUIRED = 'WAITING_FOR_CUSTOMER_RGC_REQUIRED',
    EXTRACTION_REQUIRED = 'EXTRACTION_REQUIRED',
    WAITING_FOR_CUSTOMER_EXTRACTION_REQUIRED = 'WAITING_FOR_CUSTOMER_EXTRACTION_REQUIRED',
    WAITING_FOR_EXTRACTION = 'WAITING_FOR_EXTRACTION',
    EXTRACTION_COMPLETE = 'EXTRACTION_COMPLETE',
    ENTRY_QC_START = 'ENTRY_QC_START',
    ENTRY_QC = 'ENTRY_QC',
    ENTRY_QC_OK = 'ENTRY_QC_OK',
    ENTRY_QC_FAILED = 'ENTRY_QC_FAILED',
    QUANTIFICATION = 'QUANTIFICATION',
    ON_HOLD_SAMPLE = 'ON_HOLD_SAMPLE',
    WAITING_FOR_CUSTOMER_ON_HOLD_SAMPLE = 'WAITING_FOR_CUSTOMER_ON_HOLD_SAMPLE',
    WAITING_FOR_CUSTOMER = 'WAITING_FOR_CUSTOMER',
    WAITING_FOR_REPLACEMENT = 'WAITING_FOR_REPLACEMENT',
    PROCEED_WITH_PROCESSING = 'PROCEED_WITH_PROCESSING',
    PROCESSED = 'PROCESSED',
    COMPLETE = 'COMPLETE',
    CANCELLED = 'CANCELLED',
}

export enum OrderSearchType {
    ORDER_ID = 'ORDER_ID',
    ORDER_NUMBER = 'ORDER_NUMBER',
    CUSTOMER_ID = 'CUSTOMER_ID',
}

export enum ProductionOrderStatus {
    CONFIRMED = 'CONFIRMED',
    COMPLETE = 'COMPLETE',
    INVOICED = 'INVOICED',
    NOINVOICEPREPAID = 'NOINVOICEPREPAID',
    NOINVOICE = 'NOINVOICE',
    CANCELLED = 'CANCELLED',
    ORDER_SET_UP = 'ORDER_SET_UP',
    WAITING_FOR_MATERIAL = 'WAITING_FOR_MATERIAL',
    IN_PROCESS = 'IN_PROCESS',
    PARTIAL_RESULTS = 'PARTIAL_RESULTS',
    FINAL_RESULTS = 'FINAL_RESULTS',
}

export enum ProductionSite {
    EBE = 'EBE',
    HSV = 'HSV',
    TOK = 'TOK',
    GAL = 'GAL',
    SKB = 'SKB',
    US_EBDI = 'US_EBDI',
    BGL = 'BGL',
}

export enum QcReportName {
    FINAL_LIB_QC_REPORT = 'FINAL_LIB_QC_REPORT',
    PREHYB_LIB_QC_REPORT = 'PREHYB_LIB_QC_REPORT',
    LIB_QC1_REPORT = 'LIB_QC1_REPORT',
    LIB_QC2_REPORT = 'LIB_QC2_REPORT',
    ENTRY_QC_REPORT = 'ENTRY_QC_REPORT',
}

export enum RunQcStatus {
    FAILED = 'FAILED',
    PASSED = 'PASSED',
    PENDING = 'PENDING',
}

export enum SampleReceptionStatus {
    RECEIVED = 'RECEIVED',
    DESTROYED = 'DESTROYED',
    EMPTY = 'EMPTY',
    BARCODE_LOOSE = 'BARCODE_LOOSE',
    MINOR_ISSUE = 'MINOR_ISSUE',
}

export enum UpdateType {
    APPEND = 'APPEND',
    OVERRIDE = 'OVERRIDE',
}
