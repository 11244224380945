import { Result } from 'antd'

export const Forbidden = () => {
  return (
    <Result
      status='403'
      title='Forbidden'
      subTitle='Sorry, you are not allowed to access the page you requested.'
    />
  )
}

export default Forbidden
