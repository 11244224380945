import { lazy } from 'react'
import { createBrowserRouter } from 'react-router-dom'
import { MainLayout } from '../components/layout/MainLayout'
import withRole from './guard'
import { RoleRights } from '../provider/roles'
import { BusinessUnit } from '../features/order/order'

const Forbidden = lazy(() => import('../auth/Forbidden'))
const ClientSideError = lazy(() => import('../components/errors/ClientSideError'))
const NotFound = lazy(() => import('../components/errors/NotFound'))

const ProtectedTaskList = withRole(lazy(() => import('../features/tasks/TaskList')))
const ProtectedOrderList = withRole(lazy(() => import('../features/order/OrderList')), RoleRights.READ, [BusinessUnit.NGS, BusinessUnit.MICRO_ARRAY])
const ProtectedProjectList = withRole(lazy(() => import('../features/project/search/ProjectList')), RoleRights.READ, [BusinessUnit.NGS, BusinessUnit.MICRO_ARRAY])
const ProtectedProjectSetup = withRole(lazy(() => import('../features/project/setup/ProjectSetupWrapper')), RoleRights.READ, [BusinessUnit.NGS, BusinessUnit.MICRO_ARRAY])
const ProtectedProjectOverview = withRole(lazy(() => import('../features/project/overview/ProjectOverviewWrapper')), RoleRights.READ, [BusinessUnit.NGS,BusinessUnit.MICRO_ARRAY])
const MicroArrayPlateDetails = withRole(lazy(() => import('../features/project/plate/MicroArrayPlateDetails')), RoleRights.READ, [BusinessUnit.MICRO_ARRAY])
const ProtectedOrderDetails = withRole(lazy(() => import('../features/order/OrderDetailsWrapper')))
const ProtectedKitComponentConfiguration = withRole(lazy(() => import('../features/settings/kitcomponents/KitComponentConfiguration')), RoleRights.ADMIN)
const ProtectedOrderSetupAutomationConfiguration = withRole(lazy(() => import('../features/settings/orderautomation/OrderSetupAutomationConfiguration')), RoleRights.ADMIN)

export const router = createBrowserRouter([
  {
    element: <MainLayout/>,
    children: [
      { path: '/', element: <ProtectedOrderList/>, errorElement: <ClientSideError/> },
      { path: '/tasks', element: <ProtectedTaskList/>, errorElement: <ClientSideError/> },
      { path: '/projects', element: <ProtectedProjectList/>, errorElement: <ClientSideError/> },
      {
        path: '/projects/:projectId',
        children: [
          { path: 'project-setup', element: <ProtectedProjectSetup/> },
          { path: 'overview', element: <ProtectedProjectOverview /> }
        ]
      },
      {
        path: '/projects/:projectId/plates/:plateId', element: <MicroArrayPlateDetails/>, errorElement: <NotFound/>,
      },
      {
        path: '/orders/:orderId', element: <ProtectedOrderDetails/>, errorElement: <NotFound/>,
        children: [
          { path: 'order-processing-details', element: <ProtectedOrderDetails/> },
          { path: 'reads-qc', element: <ProtectedOrderDetails/> },
          { path: 'data-delivery', element: <ProtectedOrderDetails/> },
          { path: 'invoice-data', element: <ProtectedOrderDetails/> },
          { path: 'order-data', element: <ProtectedOrderDetails/> },
          { path: 'questionnaire', element: <ProtectedOrderDetails/> },
          { path: 'order-setup', element: <ProtectedOrderDetails/> }
        ]
      },
      {
        path: '/settings', children: [
          { path: 'kit-components', element: <ProtectedKitComponentConfiguration/> },
          { path: 'order-automation', element: <ProtectedOrderSetupAutomationConfiguration/> }
        ]
      },
      { path: '/forbidden', element: <Forbidden/> },
      { path: '*', element: <NotFound/> }
    ],
    errorElement: <ClientSideError/>
  }
])
