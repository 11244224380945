import { msalInstance } from '../auth/mintAuthenticationProvider'
import { microsoftAuthConfiguration } from '../auth/mintAuthConfiguration'
import { getStoredAccessToken, isTestMode } from '../testMode'
import { BrowserAuthError, InteractionRequiredAuthError } from '@azure/msal-browser'

const getMsalToken = async () => {
  const instance = await msalInstance()
  const authenticationParameters = await microsoftAuthConfiguration()
  const account = instance.getAllAccounts().filter(account => account.tenantId === authenticationParameters.tenantId)[0]

  if (!account) {
    await instance.loginRedirect(authenticationParameters)
    return
  }

  const tokenRequest = {
    account: account,
    scopes: authenticationParameters.scopes,
  }
  try {
    const tokenResponse = await instance.acquireTokenSilent(tokenRequest)
    return tokenResponse.accessToken
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await instance.loginRedirect(authenticationParameters)
    } else if (error instanceof BrowserAuthError) {
      console.error('BrowserAuthError when trying to acquire token, clearing storage', error)
      localStorage.clear()
      window.location.reload()
    } else {
      console.error('Unhandled error when trying to acquire token:', error)
    }
  }
}

export const accessToken = async () => {
  return isTestMode() ? getStoredAccessToken() : getMsalToken()
}
