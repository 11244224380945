import { EventType, PublicClientApplication, SilentRequest } from '@azure/msal-browser'
import { msalInstance } from './mintAuthenticationProvider'

const loginSuccessCallback = (msalApplication: PublicClientApplication) => {
  return msalApplication.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as SilentRequest).account) {
      const account = (event.payload as SilentRequest).account
      msalApplication.setActiveAccount(account || null)
    }
  })
}

export const addLoginSuccessCallback = () => {
  msalInstance().then(msalApplication => {
    const callbackId = loginSuccessCallback(msalApplication)
    return () => {
      if (callbackId) {
        msalApplication.removeEventCallback(callbackId)
      }
    }
  })
}