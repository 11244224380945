import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import './lib/i18n/i18n'
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react'
import { msalInstance } from './auth/mintAuthenticationProvider'
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { isTestMode } from './testMode'
import { getAppInsights } from './lib/appinsights/config'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { AppInsightsProvider } from './provider/AppInsightsProvider'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const renderUnprotectedApp = () => {
  root.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>
  )
}

const renderMsalProtectedApp = (appInsights: ApplicationInsights | null) => {
  msalInstance().then((msalApplication: PublicClientApplication) => {
    return root.render(
      <React.StrictMode>
        <MsalProvider instance={msalApplication}>
          <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            {appInsights ? (
              <AppInsightsProvider appInsights={appInsights}>
                <App/>
              </AppInsightsProvider>
            ) : (
              <App/>
            )}
          </MsalAuthenticationTemplate>
        </MsalProvider>
      </React.StrictMode>
    )
  })
}

if (isTestMode()) {
  renderUnprotectedApp()
} else {
  getAppInsights().then(instance => {
    if (instance) {
      instance.loadAppInsights()
      renderMsalProtectedApp(instance)
    } else {
      renderMsalProtectedApp(null)
    }
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
