import React from 'react'
import Forbidden from '../auth/Forbidden'
import { Spin } from 'antd'
import { RoleRights } from '../provider/roles'
import { hasRightAssigned } from '../features/order/orderAccess'
import { BusinessUnit } from '../features/order/order'

const withRole = (Component: React.ComponentType, roleRight = RoleRights.READ, businessUnits = [BusinessUnit.NGS]) => {
  const WithRoleComponent = () => {
    const hasRole = hasRightAssigned(businessUnits, roleRight)

    if (hasRole === null || hasRole === undefined) {
      return <Spin/>
    }

    if (!hasRole) {
      return <Forbidden/>
    }

    return <Component/>
  }

  WithRoleComponent.displayName = `WithRole(${Component.displayName ?? Component.name ?? 'Component'})`

  return WithRoleComponent
}

export default withRole
