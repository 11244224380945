import { Layout } from 'antd'

const { Footer: AntdFooter } = Layout

export const Footer = () => {
  return (
    <AntdFooter>
      MINT ©2024
    </AntdFooter>
  )
}
