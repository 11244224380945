export enum RoleRights {
    READ = 'Read',
    EDIT = 'Edit',
    ADMIN = 'Admin',
    GXP_EDIT = 'GxP.Edit'
}

export enum Roles {
    EBE_READ = 'NGS.EBE.Read',
    EBE_EDIT = 'NGS.EBE.Edit',
    EBE_ADMIN = 'NGS.EBE.Admin',
    EBE_GXP = 'NGS.EBE.GxP.Edit',
    MICRO_ARRAY_GAL_READ = 'MICRO_ARRAY.GAL.Read',
    MICRO_ARRAY_GAL_EDIT = 'MICRO_ARRAY.GAL.Edit',
    MICRO_ARRAY_GAL_ADMIN = 'MICRO_ARRAY.GAL.Admin',
    MICRO_ARRAY_SKB_READ = 'MICRO_ARRAY.SKB.Read',
    MICRO_ARRAY_SKB_EDIT = 'MICRO_ARRAY.SKB.Edit',
    MICRO_ARRAY_SKB_ADMIN = 'MICRO_ARRAY.SKB.Admin',
    MICRO_ARRAY_EBE_READ = 'MICRO_ARRAY.EBE.Read',
    MICRO_ARRAY_EBE_EDIT = 'MICRO_ARRAY.EBE.Edit',
    MICRO_ARRAY_EBE_ADMIN = 'MICRO_ARRAY.EBE.Admin',
    MICRO_ARRAY_US_EBDI_READ = 'MICRO_ARRAY.US_EBDI.Read',
    MICRO_ARRAY_US_EBDI_EDIT = 'MICRO_ARRAY.US_EBDI.Edit',
    MICRO_ARRAY_US_EBDI_ADMIN = 'MICRO_ARRAY.US_EBDI.Admin',
}

export type RoleState = {
    [key in Roles]?: boolean;
} & {
    [key: string]: boolean;
}
