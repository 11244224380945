import { BrowserHistoryOptions, createBrowserHistory } from 'history'
import { ReactPlugin } from '@microsoft/applicationinsights-react-js'
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { getConfiguration } from '../../configuration/azure'
import { AxiosResponse } from 'axios'

const browserHistory = createBrowserHistory({ basename: '' } as BrowserHistoryOptions)
const reactPlugin = new ReactPlugin()

const clickPluginInstance = new ClickAnalyticsPlugin()
const clickPluginConfig = {
  autoCapture: true
}

const initializeApplicationInsights = (config: AxiosResponse) => {
  return new ApplicationInsights({
    config: {
      connectionString: config.data.applicationInsightsConnectionString,
      extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig
      }
    }
  })
}

export const getAppInsights = async () => {
  return getConfiguration().then(config => {
    if (config.data.applicationInsightsEnabled) {
      return initializeApplicationInsights(config)
    }
  })
}